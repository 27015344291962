import {
  faBars,
  faNetworkWired,
  faSearch,
  faTags,
  faCheck,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { connect } from 'react-redux';
import {
  Navbar,
  Nav,
  Container,
  Form,
  Row,
  Col,
  FormControl,
  Accordion,
  Modal,
} from 'react-bootstrap';
import './Header.css';
import SearchModal from './SearchModal';
import Menu from './menu/Menu';
import HttpService from '../../../service/shared/HttpService';
import MobMenu from './menu/MobMenu';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { searchActionType } from '../../../redux/actions/search/searchAction';

import BrandMenu from './menu/BrandMenu';
import { withRouter } from 'react-router-dom';
import BrandMenuMob from './menu/BrandMenuMob';
import { accountActionType } from '../../../redux/actions/auth/accountAction';
import { globalActionType } from '../../../redux/actions/shared/globalAction';
import Translation from 'i18next';
import OnBoardingForm from './OnBoardingForm';
import AutoSuggest from '../auto-suggest/AutoSuggest';
import Loading from '../UI/Loading';
import CartPopUp from './CartPopUp';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navStatus: 'hide',
      showMenu1: false,
      showMenu2: false,
      productCategoryList: [],
      productCategoryItemList: [],
      brandList: [],
      brandItemList: [],
      isLoading: true,
      redirect: false,
      searchValue: '',
      searchType: '2',
      openSearchBar: false,
      currencyListShowToggler: false,
      localeListToggler: false,
      signiningOut: false,
      availableLangs: [
        {
          key: 'en',
          dir: 'ltr',
          displayName: 'English (GB)',
          flag: 'https://www.ethiopianairlines.com/ResourcePackages/Ethiopian/assets/flags/svg/united-kingdom.svg',
        },
        {
          key: 'zh',
          dir: 'ltr',
          displayName: '한국어',
          flag: 'https://www.ethiopianairlines.com/ResourcePackages/Ethiopian/assets/flags/svg/china.svg',
        },
        {
          key: 'ar',
          dir: 'rtl',
          displayName: 'العربية',
          flag: 'https://www.ethiopianairlines.com/ResourcePackages/Ethiopian/assets/flags/svg/saudi-arabia.svg',
        },
      ],
      isOnBoardingModalOpened: false,
    };
  }
  openDrawerNav = (status) => {
    this.setState({ navStatus: status });
  };
  closeOpenSearchBar = () => {
    this.setState({ openSearchBar: false, searchValue: '' });
  };
  history = null;
  async componentDidMount() {
    // this.setState({ searchType: 2 });
    let categoryUrl =
      '/MasterData/api/V1.0/ItemCategory/GetItemCategoryHierarchical?categoryLevel=1';

    let brandUrl = '/MasterData/api/V1.0/Brand/GetAll';

    let clientToken = this.props.clientToken;
    let accessToken = this.props.accessToken;

    //
    HttpService.getService(categoryUrl, clientToken, accessToken)
      .then(
        (response) => {
          let productCategoryItemList = response.data.itemCategories.sort(function (a, b) {
            let nameA = a.name.toUpperCase(); // ignore upper and lowercase
            let nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
          this.props.updateCategoryList(productCategoryItemList);
          this.setState({
            productCategoryList: productCategoryItemList,
            isLoading: false,
          });
        },
        () => {
          //
          // this.setState({ isLoading: false });
        }
      )

      .catch(() => {
        // this.props.history.push("/exception");
        this.setState({ isLoading: false });
      });
    HttpService.getService(brandUrl, clientToken, accessToken)
      .then((response) => {
        this.props.updateBrandList(
          response.data.brandsRes.sort(function (a, b) {
            let nameA = a.name.toUpperCase(); // ignore upper and lowercase
            let nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          })
        );
        this.setState({
          isLoading: false,
        });
      })
      .catch(() => {
        // this.props.history.push("/exception");
        this.setState({ isLoading: false });
      });
    document.getElementById('inlineFormInputGroup').addEventListener('blur', this.onBlurHnadler);
    document.getElementById('inlineFormInputGroup').addEventListener('focus', this.onFocusHnadler);
  }

  onFocusHnadler = () => {
    if (window.screen.width > 991) {
      //   document.getElementById("header-submit-button").style.boxShadow =
      //     "2px 0px 0px 0.1rem rgb(96 173 94 / 50%)";
      //   document.getElementById("duty-free-select-input").style.boxShadow =
      //     "-2px 0 0px 0.1rem rgb(96 173 94 / 52%)";
      //   document.getElementById("duty-free-select-input").style.border =
      //     "1px solid #2e7d32";
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('focus', this.onFocusHnadler);
    window.removeEventListener('blur', this.onBlurHnadler);
  };

  onBlurHnadler = () => {
    // document.getElementById("header-submit-button").style.boxShadow = "none";
    // document.getElementById("duty-free-select-input").style.boxShadow = "none";
    // document.getElementById("duty-free-select-input").style.border =
    //   "1px solid #ced4da";
  };

  search = () => {
    let temp = this.state.searchValue;
    if (this.state.searchValue !== '') {
      this.closeOpenSearchBar();
      this.props.updateSearchString(temp);
      this.props.updateSearchType(this.state.searchType);
      this.props.history.push({
        pathname: '/SearchResult',
        params: { searchValue: temp, searchType: this.state.searchType },
      });
    }
  };

  updateCurrency = (currency) => {
    this.props.updateCurrentCurrency(currency);
    window.location.reload();
  };

  logOut = () => {
    this.setState({ signiningOut: true });
    setTimeout(() => {
      this.setState({ signiningOut: false });
      this.props.signOut();
      this.props.history.push('/account');
    }, 1000);
  };

  getBrandList = () => {
    return this.props.brandList;
    // .filter((brand) => brand.IsVisible)
  };
  render() {
    return (
      <div className="header-container-shadow">
        {this.state.signiningOut ? <Loading /> : null}
        <Modal
          show={this.state.isOnBoardingModalOpened}
          onHide={() => this.setState({ isOnBoardingModalOpened: false })}
          backdrop="static"
          animation={false}
          centered
        >
          <Modal.Header style={{ borderBottom: 'none' }} closeButton>
            <h5>On Boarding</h5>
          </Modal.Header>
          <Modal.Body className="px-5 pb-4 pt-0 modal-on-boarding">
            <Row>
              <OnBoardingForm />
            </Row>
          </Modal.Body>
        </Modal>
        <Navbar collapseOnSelect expand="lg" bg="white" variant="light" className="py-0">
          <Container className="logo-container-header et-container sm-nav-container">
            <span
              // eventKey={2}
              href="#memes"
              style={{ marginTop: '28px' }}
              className="mob-menu nav-link cursor-pointer"
              onClick={() => {
                this.openDrawerNav('show');
              }}
            >
              <FontAwesomeIcon icon={faBars} />
            </span>
            <Nav className="header-search-nav">
              <Nav.Link href="/">
                <img
                  src={process.env.PUBLIC_URL + '/duty-free-logo-mobile.png'}
                  width="200"
                  height="58"
                  className="m-auto"
                  alt="logo"
                  loading="lazy"
                />
              </Nav.Link>
            </Nav>
            <Nav className="pt-2 mob-top-nav-link">
              <SearchModal />
              {this.props.isSignedIn ? (
                <Nav.Link
                  href="#"
                  className="pt-0 d-flex flex-column align-items-center"
                  onClick={() => this.props.history.push('/account/dashboard')}
                >
                  <img
                    className="icon-on-header"
                    alt="user icon"
                    src={process.env.PUBLIC_URL + '/icons/person_64px.png'}
                    loading="lazy"
                  />
                  <span className="d-flex duty-user-name-container">
                    {this.props.userInfo.firstName + ' ' + this.props.userInfo.lastName}
                  </span>
                </Nav.Link>
              ) : (
                <Nav.Link
                  href="#"
                  className="pt-0 d-flex flex-column align-items-center"
                  onClick={() => this.props.history.push('/account')}
                >
                  <img
                    // className="icon-on-header"
                    style={{ width: '40px', height: '40px' }}
                    alt="user icon"
                    src={process.env.PUBLIC_URL + '/icons/person_64px.png'}
                    loading="lazy"
                  />
                </Nav.Link>
              )}
              <Nav.Link
                eventKey={2}
                href="#"
                style={{
                  backgroundImage: 'url(' + process.env.PUBLIC_URL + '/icons/shopping_bag_64px.png',
                }}
                className="d-flex p-0 shopping-cart-icon-container mx-2"
                onClick={() => this.props.history.push('/cart')}
              >
                <span className="number-of-items-in-the-cart">
                  {Object.keys(this.props.cart.cart).reduce((total, key) => {
                    return total + this.props.cart.cart[key].quantity;
                  }, 0)}
                </span>
              </Nav.Link>
            </Nav>
            <OutsideClickHandler
              onOutsideClick={() => {
                if (this.state.navStatus === 'show') {
                  this.openDrawerNav('hide');
                }
              }}
            >
              <div id="mySidenav" className={'sidenav ' + this.state.navStatus}>
                {/* <img
                  src={process.env.PUBLIC_URL + "images/et-logo.png"}
                  width="200"
                  className="m-auto"
                  loading="lazy"
                /> */}
                <a
                  href="/"
                  className="closebtn"
                  onClick={(event) => {
                    event.preventDefault();
                    this.openDrawerNav('hide');
                  }}
                  aria-label="Close"
                  role="button"
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </a>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {' '}
                      <span className="pe-3">
                        {' '}
                        <FontAwesomeIcon icon={faNetworkWired} />
                      </span>
                      <span>{Translation.t('category')}</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <MobMenu categoryList={this.props.productCategoryList} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <span className="pe-3">
                        {' '}
                        <FontAwesomeIcon icon={faTags} />
                      </span>
                      <span>{Translation.t('brand')}</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <BrandMenuMob brandList={this.getBrandList()} />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <a href="/new-arrivals">{Translation.t('new arrivals')}</a>
                <a href="/best-sellers">{Translation.t('best sellers')}</a>
                <a href="/travel-exclusives">{Translation.t('travel exclusives')}</a>
                <a href="/deals">{Translation.t('deals')}</a>
                <a href="/offers">{Translation.t('Promotions')}</a>
                <a
                  href="https://corporate.ethiopianairlines.com/AboutEthiopian"
                  target="_blank"
                  rel="noreferrer"
                >
                  {Translation.t('about us')}
                </a>
                <a
                  href="/feed-back"
                  aria-label="Feedback"
                  style={{
                    width: '100%',
                    position: 'absolute',
                    bottom: '56px',
                    borderTop: '1px solid #ddd',
                  }}
                >
                  {Translation.t('feedback')}
                </a>
              </div>
            </OutsideClickHandler>
          </Container>
          <Container className="logo-container-header et-container md-nav-container w-100">
            <Row className="w-100 justify-content-start align-items-end">
              <Col md={8} className="d-flex justify-content-start">
                <Navbar.Brand href="/">
                  {/* deskotp logo */}
                  <img
                    className="mr-3"
                    src={process.env.PUBLIC_URL + '/new-logo/sheba-duty-free-logo-v2.png'}
                    width="350"
                    height="79"
                    alt="logo"
                    loading="lazy"
                  />
                </Navbar.Brand>
              </Col>

              <Col md={4} className="lang-curency-container">
                <Nav className="pt-2 top-nav-link">
                  {/* currency related things */}
                  <Nav.Link
                    eventKey={2}
                    className="pt-0 d-flex flex-column align-items-start me-2"
                    style={{ marginTop: '4px' }}
                    href="#"
                  >
                    <div onClick={() => this.setState({ currencyListShowToggler: true })}>
                      <span className="duty-user-name-container">{this.props.currency}</span>
                      <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
                    </div>

                    <OutsideClickHandler
                      onOutsideClick={() => {
                        if (this.state.currencyListShowToggler) {
                          this.setState({
                            currencyListShowToggler: !this.state.currencyListShowToggler,
                          });
                        }
                      }}
                    >
                      <div
                        className={
                          this.state.currencyListShowToggler
                            ? 'd-flex currency-list-container flex-column align-items-start'
                            : 'd-none'
                        }
                      >
                        {this.props.currencyList.map((currency, index) => {
                          return (
                            <div
                              key={index}
                              className="duty-user-name-container d-flex justify-content-between w-100 currency-list-child"
                              onClick={() => this.updateCurrency(currency.currencyCode)}
                            >
                              <span>
                                {this.props.currency === currency.currencyCode && (
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    className="me-1 mt-1"
                                    style={{
                                      position: 'absolute',
                                      left: '10px',
                                    }}
                                  />
                                )}
                                {currency.currencyName}
                              </span>
                              <span>{currency.currencyCode}</span>
                            </div>
                          );
                        })}
                      </div>
                    </OutsideClickHandler>
                  </Nav.Link>
                  <Nav.Link
                    eventKey={2}
                    className="pt-0 d-flex flex-column align-items-center"
                    style={{ marginTop: '4px' }}
                    href="#"
                  >
                    <div
                      onClick={() =>
                        this.setState({
                          localeListToggler: !this.state.localeListToggler,
                        })
                      }
                    >
                      <span className="duty-user-name-container text-uppercase current-lang">
                        {this.state.availableLangs.map((lang) => {
                          if (this.props.locale === lang.key) {
                            return (
                              <img
                                key={lang.key}
                                src={lang.flag}
                                alt="locale"
                                className="language-img me-2"
                                loading="lazy"
                              />
                            );
                          }
                        })}
                        {this.props.locale}
                      </span>
                      <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          if (this.state.localeListToggler) {
                            this.setState({
                              localeListToggler: !this.state.localeListToggler,
                            });
                          }
                        }}
                      >
                        <div
                          className={
                            this.state.localeListToggler ? 'd-flex lang-list-container' : 'd-none'
                          }
                        >
                          {this.state.availableLangs.map((lang) => {
                            return (
                              <span
                                key={lang.key}
                                onClick={() => {
                                  this.props.updateLocale(lang.key);
                                  Translation.changeLanguage(lang.key);
                                }}
                                className="duty-user-name-container d-flex px-1"
                              >
                                {this.props.locale === lang.key && (
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    className="mt-1"
                                    style={{
                                      position: 'absolute',
                                      left: '6px',
                                    }}
                                  />
                                )}
                                <img src={lang.flag} className="language-img me-2" loading="lazy" />
                                {lang.displayName}
                              </span>
                            );
                          })}
                        </div>
                      </OutsideClickHandler>
                    </div>
                  </Nav.Link>
                </Nav>
              </Col>
            </Row>
          </Container>
        </Navbar>
        <Navbar collapseOnSelect expand="lg" bg="white" variant="light" className="pb-0 pt-3">
          <Container className="secondary-header pl-md-0 pl-lg-0 md-nav-container">
            {this.state.openSearchBar ? (
              <Row className="w-100">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="w-100">
                  <div id="search-form-out-side-handler" className="w-100">
                    <OutsideClickHandler
                      onOutsideClick={() => this.setState({ openSearchBar: false })}
                    >
                      <Container className="w-100 mb-3">
                        <Navbar.Collapse id="responsive-navbar-nav w-100">
                          <Nav className="me-auto w-100">
                            <Form
                              className="w-100 header-search-desktop-form d-flex align-items-centerm mx-3"
                              onSubmit={(event) => {
                                event.preventDefault();
                                this.search();
                              }}
                            >
                              <FormControl
                                id="inlineFormInputGroup"
                                autoFocus={true}
                                placeholder={Translation.t('search')}
                                autoComplete="off"
                                // defaultValue=""
                                className="rounded-0 w-100"
                                value={this.state.searchValue}
                                onChange={(event) => {
                                  this.setState({
                                    searchValue: event.target.value,
                                  });
                                  // this.props.updateSearchString(event.target.value);
                                }}
                                required
                              />
                              <FontAwesomeIcon
                                icon={faSearch}
                                color="#718289"
                                style={{
                                  marginTop: '15px',
                                  fontSize: '16px',
                                }}
                              />
                            </Form>
                            {this.state.searchValue !== '' && this.state.searchValue.length > 1 && (
                              <div className="auto-suggest-container p-0 m-0">
                                <AutoSuggest
                                  searchKey={this.state.searchValue}
                                  history={this.props.history}
                                  closeOpenSearchBar={this.closeOpenSearchBar}
                                />
                              </div>
                            )}

                            <span
                              role="button"
                              className="d-flex p-0 shopping-cart-icon-container-desktop mx-2 nav-link"
                            >
                              <img
                                className="icon-on-header-desktop"
                                alt="cart icon"
                                src={process.env.PUBLIC_URL + '/icons/shopping-cart.png'}
                                loading="lazy"
                              />
                              <span className="number-of-items-in-the-cart shopping-cart-number-indicatior text-white">
                                {Object.keys(this.props.cart.cart).reduce((total, key) => {
                                  return total + this.props.cart.cart[key].quantity;
                                }, 0)}
                              </span>
                              <div className="shopping-cart-modal p-3">
                                {Object.keys(this.props.cart.cart).length !== 0 ? (
                                  <>
                                    <CartPopUp />
                                    <span className="number-of-items-in-the-cart">
                                      <Nav.Link
                                        href="#"
                                        className="p-2 d-flex align-items-center btn w-100  duty-free-outline-primary-btn justify-content-center"
                                        onClick={() => this.props.history.push('/cart')}
                                      >
                                        {Translation.t('open cart')}
                                      </Nav.Link>
                                    </span>
                                  </>
                                ) : (
                                  <div>
                                    <span>{Translation.t('your shopping bag is empty')}</span>
                                    <Nav.Link
                                      href="#"
                                      className="p-2 d-flex flex-column align-items-center btn w-100 my-4 duty-free-outline-primary-btn"
                                      onClick={() => this.props.history.push('/brands')}
                                    >
                                      {Translation.t('show all brands')}
                                    </Nav.Link>
                                  </div>
                                )}
                              </div>
                            </span>
                            <span
                              role="button"
                              className="d-flex p-0 account-icon-container-desktop mx-2 nav-link"
                            >
                              <img
                                className="icon-on-header-desktop"
                                alt="user icon"
                                src={process.env.PUBLIC_URL + '/icons/user.png'}
                                loading="lazy"
                              />
                              <div className="profile-modal p-3">
                                {this.props.isSignedIn ? (
                                  <div className="d-flex flex-column align-items-start px-3">
                                    <Nav.Link
                                      href="#"
                                      className="pt-0 d-flex flex-column align-items-center"
                                      onClick={() => this.props.history.push('/account/dashboard')}
                                    >
                                      <span
                                        className="d-flex duty-user-name-container"
                                        style={{ fontSize: '20px' }}
                                      >
                                        {this.props.userInfo.firstName +
                                          ' ' +
                                          this.props.userInfo.lastName}
                                      </span>
                                    </Nav.Link>
                                    <Nav.Link
                                      href="#"
                                      className="p-2 my-3 d-flex flex-column align-items-center duty-free-outline-primary-btn btn w-100"
                                      onClick={() => this.logOut()}
                                    >
                                      {Translation.t('log out')}
                                    </Nav.Link>
                                  </div>
                                ) : (
                                  <div className="d-flex flex-column align-items-start px-3">
                                    <Nav.Link
                                      href="#"
                                      className="p-2 d-flex flex-column align-items-center duty-free-outline-primary-btn btn w-100 my-3"
                                      onClick={() => this.props.history.push('/account')}
                                    >
                                      {Translation.t('login')}
                                    </Nav.Link>
                                    <Nav.Link href="#" className="p-0 new-customer-label">
                                      {Translation.t('are you new customer')}
                                    </Nav.Link>
                                    <Nav.Link
                                      href="#"
                                      className="p-0 "
                                      onClick={() => this.props.history.push('/registration')}
                                    >
                                      {Translation.t('sign up for free')}
                                    </Nav.Link>
                                  </div>
                                )}
                              </div>
                            </span>
                          </Nav>
                        </Navbar.Collapse>
                      </Container>
                    </OutsideClickHandler>
                  </div>
                </Col>
              </Row>
            ) : (
              <>
                <Row className="w-100 justify-content-start">
                  <Col md={8} className="d-flex justify-content-start">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                      <Nav className="w-100 justify-content-between">
                        <span
                          role="button"
                          className="dropdown top-nav-item text-uppercase nav-link"
                          onMouseEnter={() => this.setState({ showMenu1: true })}
                          onMouseLeave={() => this.setState({ showMenu1: false })}
                        >
                          {Translation.t('category')}
                          {this.state.showMenu1 && (
                            <Menu
                              showMenu={this.state.showMenu1}
                              menuList={this.state.menuList1}
                              categoryList={this.props.productCategoryList}
                              isLoading={this.state.isLoading}
                            />
                          )}
                        </span>
                        <span
                          role="button"
                          className="dropdown top-nav-item text-uppercase nav-link"
                          onMouseEnter={() => this.setState({ showMenu2: true })}
                          onMouseLeave={() => this.setState({ showMenu2: false })}
                        >
                          {Translation.t('brand')}
                          {this.state.showMenu2 && (
                            <BrandMenu
                              history={this.props.history}
                              showMenu={this.state.showMenu2}
                              menuList={this.state.menuList2}
                              brandList={this.getBrandList()}
                              isLoading={this.state.isLoading}
                            />
                          )}
                        </span>
                        <Nav.Link href="/new-arrivals" className="top-nav-item text-uppercase">
                          {Translation.t('new arrivals')}
                        </Nav.Link>
                        <Nav.Link href="/best-sellers" className="top-nav-item text-uppercase">
                          {Translation.t('best sellers')}
                        </Nav.Link>
                        <Nav.Link href="/travel-exclusives" className="top-nav-item text-uppercase">
                          {Translation.t('travel exclusives')}
                        </Nav.Link>
                        <Nav.Link href="/deals" className="top-nav-item text-uppercase">
                          {Translation.t('deals')}
                        </Nav.Link>
                        <Nav.Link href="/promotions" className="top-nav-item text-uppercase">
                          {Translation.t('Promotions')}
                        </Nav.Link>
                        {/* <Nav.Link
                  className="top-nav-item"
                  href="https://corporate.ethiopianairlines.com/AboutEthiopian"
                  target="_blank"
                  rel="noreferrer"
                >
                  {Translation.t("about us")}
                </Nav.Link> */}
                        {/* <Nav.Link
                  className={this.props.onBoardingStatus ? "top-nav-item activated" : "top-nav-item"}
                  target="_blank"
                  onClick={(e) => { e.preventDefault(); this.setState({ isOnBoardingModalOpened: true }); }}
                  rel="noreferrer"
                >
                  {Translation.t("on boarding")}
                </Nav.Link> */}
                      </Nav>
                    </Navbar.Collapse>
                  </Col>
                  <Col md={4} className="d-flex justify-content-start ">
                    <Nav className="mb-3 w-75">
                      <Form
                        className="w-md-75 w-lg-50 header-search-desktop-form d-flex align-items-center mx-3"
                        onSubmit={(event) => {
                          event.preventDefault();
                          this.search();
                        }}
                      >
                        <FormControl
                          id="inlineFormInputGroup"
                          onClick={() => {
                            this.setState({ openSearchBar: true });
                          }}
                          placeholder={Translation.t('search')}
                          autoComplete="off"
                          // defaultValue=""
                          className="rounded-0"
                          value={this.state.searchValue}
                          onChange={(event) => {
                            this.setState({ searchValue: event.target.value });
                            // this.props.updateSearchString(event.target.value);
                          }}
                          required
                        />
                        <FontAwesomeIcon
                          icon={faSearch}
                          style={{ fontSize: '16px' }}
                          color="#718289"
                        />
                      </Form>

                      <span
                        role="button"
                        className="d-flex p-0 shopping-cart-icon-container-desktop mx-2 nav-link"
                      >
                        <img
                          className="icon-on-header-desktop"
                          alt="cart icon"
                          src={process.env.PUBLIC_URL + '/icons/shopping-cart.png'}
                          loading="lazy"
                        />
                        <span className="number-of-items-in-the-cart shopping-cart-number-indicatior text-white">
                          {Object.keys(this.props.cart.cart).reduce((total, key) => {
                            return total + this.props.cart.cart[key].quantity;
                          }, 0)}
                        </span>
                        <div className="shopping-cart-modal p-3">
                          {Object.keys(this.props.cart.cart).length !== 0 ? (
                            <>
                              <CartPopUp />
                              <span className="number-of-items-in-the-cart">
                                <Nav.Link
                                  href="#"
                                  className="p-2 d-flex align-items-center btn w-100  duty-free-outline-primary-btn justify-content-center"
                                  onClick={() => this.props.history.push('/cart')}
                                >
                                  {Translation.t('open cart')}
                                </Nav.Link>
                              </span>
                            </>
                          ) : (
                            <div>
                              <span>{Translation.t('your shopping bag is empty')}</span>
                              <Nav.Link
                                href="#"
                                className="p-2 d-flex flex-column align-items-center btn w-100 my-4 duty-free-outline-primary-btn"
                                onClick={() => this.props.history.push('/brands')}
                              >
                                {Translation.t('show all brands')}
                              </Nav.Link>
                            </div>
                          )}
                        </div>
                      </span>
                      <span
                        role="button"
                        className="d-flex p-0 account-icon-container-desktop mx-2 nav-link"
                      >
                        <img
                          className="icon-on-header-desktop"
                          alt="user icon"
                          src={process.env.PUBLIC_URL + '/icons/user.png'}
                          loading="lazy"
                        />
                        <div className="profile-modal p-3">
                          {this.props.isSignedIn ? (
                            <div className="d-flex flex-column align-items-start px-3">
                              <Nav.Link
                                href="#"
                                className="pt-0 d-flex flex-column align-items-center"
                                onClick={() => this.props.history.push('/account/dashboard')}
                              >
                                <span
                                  className="d-flex duty-user-name-container"
                                  style={{ fontSize: '20px' }}
                                >
                                  {this.props.userInfo.firstName +
                                    ' ' +
                                    this.props.userInfo.lastName}
                                </span>
                              </Nav.Link>
                              <Nav.Link
                                href="#"
                                className="p-2 my-3 d-flex flex-column align-items-center duty-free-outline-primary-btn btn w-100"
                                onClick={() => this.logOut()}
                              >
                                {Translation.t('log out')}
                              </Nav.Link>
                            </div>
                          ) : (
                            <div className="d-flex flex-column align-items-start px-3">
                              <Nav.Link
                                href="#"
                                className="p-2 d-flex flex-column align-items-center duty-free-outline-primary-btn btn w-100 my-3"
                                onClick={() => this.props.history.push('/account')}
                              >
                                {Translation.t('login')}
                              </Nav.Link>
                              <Nav.Link href="#" className="p-0 new-customer-label">
                                {Translation.t('are you new customer')}
                              </Nav.Link>
                              <Nav.Link
                                href="#"
                                className="p-0 "
                                onClick={() => this.props.history.push('/registration')}
                              >
                                {Translation.t('sign up for free')}
                              </Nav.Link>
                            </div>
                          )}
                        </div>
                      </span>
                    </Nav>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </Navbar>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    clientToken: state.authClient.clientToken,
    accessToken: state.authAccess.accessToken,
    cart: state.cart,
    currencyList: state.global.currencyList,
    productCategoryList: state.global.categoryList,
    brandList: state.global.brandList,
    currency: state.global.currency,
    userInfo: state.account.userInfo,
    isSignedIn: state.account.isSignedIn,
    locale: state.global.locale,
    onBoardingStatus: state.batch.onBoardingStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateIsSearching: (value) =>
      dispatch({
        type: searchActionType.UPDATESEARCING,
        value: value,
      }),
    updateSearchString: (value) =>
      dispatch({
        type: searchActionType.UPDATESEARCHSTRING,
        value: value,
      }),
    signOut: () =>
      dispatch({
        type: accountActionType.SIGNOUT,
      }),
    updateSearchType: (value) =>
      dispatch({
        type: searchActionType.UPDATESEARCHTYPE,
        value: value,
      }),
    updateCurrentCurrency: (value) =>
      dispatch({
        type: globalActionType.UPDATECURRENCY,
        value: value,
      }),
    updateLocale: (value) =>
      dispatch({
        type: globalActionType.UPDATELOCALE,
        value: value,
      }),
    updateCategoryList: (value) =>
      dispatch({
        type: globalActionType.UPDATECATEGORYLIST,
        value: value,
      }),
    updateBrandList: (value) =>
      dispatch({
        type: globalActionType.SETBRANDLIST,
        value: value,
      }),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
