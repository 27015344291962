import React from 'react';
import { connect } from 'react-redux';
import { getDiscount } from 'src/service/shared/product/product';
import { getSalesPrice, thousandsSeparators } from 'src/service/shared/utilits';
import Translation from 'i18next';

const CartPopUp = (props) => {
  return (
    <div>
      <p
        style={{
          color: 'rgba(0, 0, 0, 0.76)',
        }}
        className="mb-0 text-start"
      >
        {Object.keys(props.cart).length} {Translation.t('items')}
      </p>
      <hr />
      {Object.keys(props.cart).map((key) => (
        <div key={key} className="row mx-0 col-12 px-0">
          <div className="text-left my-auto col-3 px-0">
            <img
              src={
                props.cart[key].item.itemImage !== null
                  ? props.cart[key].item.itemImage[0].imageUrl
                  : ''
              }
              className="img-fluid cart-product-image m-auto"
              alt="product"
              loading="lazy"
            />
          </div>
          <div className="col-9">
            <p
              style={{
                overflow: 'hidden',
                maxLines: 1,
                textOverflow: 'clip',
                color: 'rgba(0, 0, 0, 0.76)',
              }}
              className="text-start text-black"
            >
              {props.cart[key].item.name}{' '}
            </p>
            <p
              className="text-start mb-0"
              style={{
                color: 'rgba(0, 0, 0, 0.76)',
              }}
            >
              {props.cart[key].quantity} x{' '}
              <strong className="text-black">
                {props.currency} {thousandsSeparators(
                  props.cart[key].item.isPercentage && props.cart[key]?.quantity <
                  props.cart[key]?.item?.orderQuantity 
                  ? props.cart[key].item.salesRate
                : props.cart[key].item.currentPrice)}{' '}
              </strong>
            </p>
          </div>
          <hr className="col-12 px-0 mt-3" />
        </div>
      ))}
      <p className="text-start d-flex text-black">
        <span>{Translation.t('total items')}</span>
        <span className="ml-auto">
          {props.currency}{' '}
          {thousandsSeparators(
            Object.keys(props.cart).reduce((total, key) => {
              return props.cart[key]?.item?.isPercentage ? 
              total +
                      props.cart[key].quantity *
                        getSalesPrice(props.cart[key].item.salesRate)
                  : total +
                      props.cart[key].quantity *
                        getSalesPrice(props.cart[key].item.currentPrice);
            }, 0) - getDiscount()
          )}
        </span>
      </p>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    currency: state.global.currency,
    locale: state.global.locale,
  };
};
export default connect(mapStateToProps)(CartPopUp);
