import axios from "axios";
import CookieService from "./CookieService.js";
import { store } from "../../redux/store/store.js";

class HttpService {
  static baseUrl = "";

  static getTokenServive = () => {
    let cookieService = new CookieService();
    let xsrfToken = cookieService.getCookie("XSRF-TOKEN"); //*get AntiforgeryToken from Cookies
    return axios.get(document.location.origin + "/Home/Index", {
      headers: {
        RequestVerificationToken: xsrfToken,
      },
    });
  };

  static getHeaderAndURL = (token) => {
    let headers = {
      Authorization: "Bearer " + token,
      ActionPrivilegies: "PreOrder-Access",
      ContentType: "application/json",
      Accept: "application/json",
    };
    return {
      baseUrl: HttpService.baseUrl,
      token: token,
      headers: headers,
    };
  };
  static postService = (requestData, relativePath, token, queryString = "") => {
    let headerAndURL = this.getHeaderAndURL(token);
    let baseUrl = headerAndURL.baseUrl + relativePath;
    return axios.post(baseUrl + queryString, requestData, {
      headers: headerAndURL.headers,
    });
  };

  static getMultipleService = (
    relativePath1,
    relativePath2,
    clientToken,
    accessToken,
    queryString = ""
  ) => {
    let headerAndURL = this.getHeaderAndURL(clientToken);
    headerAndURL.headers.UserToken = accessToken;
    let baseUrl1 = headerAndURL.baseUrl + relativePath1;
    let baseUrl2 = headerAndURL.baseUrl + relativePath2;
    return axios.all([
      axios.get(baseUrl1 + queryString, {
        headers: headerAndURL.headers,
      }),
      axios.get(baseUrl2 + queryString, {
        headers: headerAndURL.headers,
      }),
    ]);
  };

  static getAccessTokenServive = (token, username, password) => {
    let headerAndURL = this.getHeaderAndURL(token);
    headerAndURL.baseUrl =
      headerAndURL.baseUrl + "/User/api/V1.0/Account/SignIn";
    const params = {
      username: username,
      password: password,
    };

    return axios.post(headerAndURL.baseUrl, params, {
      headers: headerAndURL.headers,
    });
  };

  static postServiceGeneralPurpose = (
    relativePath,
    clientToken,
    accessToken,
    payload,
    ActionPrivilegies
  ) => {
    let headerAndURL = this.getHeaderAndURL(clientToken);
    headerAndURL.headers.UserToken = accessToken;
    headerAndURL.headers.ActionPrivilegies = ActionPrivilegies;

    return axios.post(headerAndURL.baseUrl + relativePath, payload, {
      headers: headerAndURL.headers,
    });
  };

  static putServiceGeneralPurpose = (
    relativePath,
    clientToken,
    accessToken,
    payload,
    ActionPrivilegies
  ) => {
    let headerAndURL = this.getHeaderAndURL(clientToken);
    headerAndURL.headers.UserToken = accessToken;
    headerAndURL.headers.ActionPrivilegies = ActionPrivilegies;

    return axios.put(headerAndURL.baseUrl + relativePath, payload, {
      headers: headerAndURL.headers,
    });
  };
  static getServiceGeneralPurpose = (
    relativePath,
    clientToken,
    accessToken,
    payload,
    ActionPrivilegies
  ) => {
    let headerAndURL = this.getHeaderAndURL(clientToken);
    headerAndURL.headers.UserToken = accessToken;
    if(ActionPrivilegies){
      headerAndURL.headers.ActionPrivilegies = ActionPrivilegies;
    }
    else{
      delete headerAndURL.headers.ActionPrivilegies;
    }
    // debugger;
    //add currency id
    let globalStore = store.getState().global;
    payload.currencyId = globalStore.currencyList.find(
      (el) => el.currencyCode == globalStore.currency
    )?.id || 2;

    return axios.get(
      headerAndURL.baseUrl + relativePath,
      { params: payload, headers: headerAndURL.headers },
      payload
    );
  };
  static getService = (
    relativePath,
    clientToken,
    accessToken,
    queryString = ""
  ) => {
    let headerAndURL = this.getHeaderAndURL(clientToken);
    headerAndURL.headers.UserToken = accessToken;
    let payload = { currencyId: 2 };
    //add currency id
    let globalStore = store.getState().global;
    payload.currencyId = globalStore.currencyList.find(
      (el) => el.currencyCode == globalStore.currency
    )?.id || 2;
    return axios.get(
      headerAndURL.baseUrl + relativePath,
      { params: payload, headers: headerAndURL.headers },
      payload
    );
  };
  static getServiceForSearch = (
    relativePath,
    clientToken,
    accessToken,
    payload,
    ActionPrivilegies
  ) => {
    let headerAndURL = this.getHeaderAndURL(clientToken);
    headerAndURL.headers.UserToken = accessToken;
    headerAndURL.headers.ActionPrivilegies = ActionPrivilegies;

    let globalStore = store.getState().global;
    payload.currencyId = globalStore.currencyList.find(
      (el) => el.currencyCode == globalStore.currency
    )?.id || 2;
    return axios.get(
      headerAndURL.baseUrl + relativePath,
      { params: payload, headers: headerAndURL.headers },
      payload
    );
  };
}

export default HttpService;
